import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import App from "./App";
import { PublicRoute } from "components";
import { HomePage, AboutPage, ContactPage } from "pages";

function Router() {
  return (
    <BrowserRouter>
      <App>
        <Switch>
          <PublicRoute path="/contact" component={ContactPage} restricted />
          <PublicRoute path="/about" component={AboutPage} />
          <PublicRoute path="/" exact component={HomePage} restricted />
        </Switch>
      </App>
    </BrowserRouter>
  );
}

export default Router;
