import React from "react";
import PropTypes from "prop-types";
import { Button as SUIButton, Icon } from "semantic-ui-react";

function renderButtonWithIcon({ content, icon, position, iconColor, ...rest }) {
  const iconStyles = { color: iconColor };
  const btnIcon =
    icon && typeof icon === "string" ? (
      <Icon name={icon} className={position} style={iconStyles} />
    ) : (
      icon
    );

  let children =
    position === "left" ? (
      <>
        {btnIcon}
        {content}
      </>
    ) : position === "right" && typeof icon === "string" ? (
      <>
        {content}
        {btnIcon}
      </>
    ) : position === "right" && typeof icon !== "string" ? (
      <>
        {content}&nbsp;{btnIcon}
      </>
    ) : null;

  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
}

function renderButton({ content, ...rest }) {
  return <ButtonContainer {...rest}>{content}</ButtonContainer>;
}

function ButtonContainer({
  size,
  as,
  textTransform,
  children,
  externalLink,
  ...rest
}) {
  const btnStyles = { textTransform };
  let buttonComponent = externalLink ? (
    <SUIButton
      size={size}
      as={as}
      style={btnStyles}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children}
    </SUIButton>
  ) : (
    <SUIButton size={size} as={as} style={btnStyles} {...rest}>
      {children}
    </SUIButton>
  );
  return buttonComponent;
}

export default function Button(props) {
  const btnComponent = props.icon
    ? renderButtonWithIcon(props)
    : renderButton(props);
  return btnComponent;
}

Button.propTypes = {
  as: PropTypes.any,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"]),
  content: PropTypes.any.isRequired,
  textTransform: PropTypes.oneOf(["uppercase", "lowercase", "capitalize"]),
  externalLink: PropTypes.bool,
};

Button.defaultProps = {
  as: null,
  icon: null,
  iconColor: null,
  size: "large",
  position: "right",
  content: "Button Label",
  textTransform: "uppercase",
  externalLink: false,
};
