import React from "react";
import PropTypes from "prop-types";
import { Icon, Image, Header } from "semantic-ui-react";

export default function TeamMemberCard({ fullname, title, location, profile }) {
  return (
    <>
      <Image src={profile} size="medium" rounded bordered />
      <Header as="h3" style={{ color: "#c13939" }}>
        {fullname}
      </Header>
      <p>{title}</p>
      <p>
        <Icon name="map marker" style={{ color: "#c13939" }} /> {location}
      </p>
    </>
  );
}

TeamMemberCard.propTypes = {
  fullname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  about: PropTypes.string,
};

TeamMemberCard.defaultProps = {
  fullname: null,
  title: null,
  location: null,
  profile: null,
  about: null,
};
