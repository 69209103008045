import React from "react";
// import PropTypes from "prop-types";

import { Container, Grid, Header } from "semantic-ui-react";
import { Button } from "components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./HeroBanner.css";

function HeroBanner({
  images,
  height,
  styles,
  header,
  tagline,
  textAlign,
  ctas,
  ...additionalProps
}) {
  // const heroImageContainerStyles = {
  //   background: `url(${image}) no-repeat center center fixed`,
  //   height,
  // };

  function renderCtas() {
    if (!ctas) return null;
    if (React.isValidElement(ctas)) return ctas;
    return ctas.map(
      ({ to, label, icon, iconColor, as, externalLink, ...rest }, index) => (
        <Button
          key={index}
          as={as}
          to={to}
          content={label}
          icon={icon}
          iconColor={iconColor}
          {...rest}
          externalLink={externalLink}
        />
      )
    );
  }

  return (
    <AliceCarousel {...additionalProps}>
      {images &&
        images.map((image, index) => {
          return (
            <div
              key={index}
              className="hero-container"
              style={{
                background: `url(${image}) no-repeat center center fixed`,
                height,
              }}
            >
              <div className="hero-overlay">
                <Grid
                  stackable
                  verticalAlign={"middle"}
                  textAlign={textAlign}
                  style={{ height: height }}
                >
                  <Grid.Column width={16} textAlign={textAlign}>
                    <Container>
                      <Header
                        as="h1"
                        textAlign={textAlign}
                        content={header}
                        inverted
                        style={{
                          margin: "0.85em 0",
                          fontWeight: "bold",
                          fontSize: "2.65em",
                        }}
                      />

                      {tagline && (
                        <Header
                          as="h1"
                          textAlign={textAlign}
                          content={tagline}
                          inverted
                          style={{
                            margin: "0.85em 0",
                            fontWeight: "400",
                            fontSize: "2.2em",
                          }}
                        />
                      )}

                      {renderCtas()}
                    </Container>
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          );
        })}
    </AliceCarousel>
  );
}

export default HeroBanner;
