export const BOHIOTV_CONTACTS = [
  {
    icon: "mail",
    text: "partners@bohiotv.com",
    link: "mailto:info@bohiotv.com",
  },
  {
    icon: "world",
    text: "www.bohiotv.com",
    link: "https://www.bohiotv.com",
  },
];
