import estimeProfile from "static/assets/img/estime.jpeg";
import sorayaProfile from "static/assets/img/soraya.jpeg";
import allyProfile from "static/assets/img/ally.jpg";

const PUBLIC_SITEMAP = [
  {
    icon: "home",
    name: "home",
    label: "Home",
    path: "/",
    internal: "true",
  },
  {
    icon: "",
    name: "about",
    label: "About",
    path: "/about",
    internal: "false",
  },
  {
    icon: "",
    name: "contact",
    label: "Contact",
    path: "/contact",
    internal: "true",
  },
  // {
  //   icon: "user",
  //   name: "login",
  //   label: "Login",
  //   path: "google.com",
  //   internal: "false",
  // },
];

const SocialMedias = [
  {
    icon: "world",
    link: "https://bohiotv.com/",
    color: "#212121",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/bohiotv/",
    color: "#4267B2",
  },
  {
    icon: "instagram",
    link: "https://www.instagram.com/bohiotv/",
    color: "#C13584",
  },
  {
    icon: "youtube",
    link: "https://www.youtube.com/channel/UCqvtwQPORZilVpra5y1xViQ",
    color: "#c4302b",
  },
  {
    icon: "twitter",
    link: "https://twitter.com/bohiotv",
    color: "#00acee",
  },
];

const constans = {
  navigations: {
    public: PUBLIC_SITEMAP,
  },
  SocialMedias,
};

export default constans;

export const NAVIGATION_LINKS = {
  PUBLIC: PUBLIC_SITEMAP,
};

export const BOHIOTV_TEAM_MEMBERS = [
  {
    fullname: "Krishna Farvil",
    title: "Founder & CEO",
    about: "",
    location: "Massachusetts",
    profile: "https://via.placeholder.com/960x1450.gif",
  },
  {
    fullname: "Mac Alabre",
    title: "Co-Founder & COO",
    about: "",
    location: "Florida",
    profile: "https://via.placeholder.com/960x1450.gif",
  },
  {
    fullname: "Myrdel Geffrard H.",
    title: "Chief Financial officer",
    about: "",
    location: "New York",
    profile: "https://via.placeholder.com/960x1450.gif",
  },
  {
    fullname: "Estime Jean Toussaint",
    title: "Business Development Executive/HT",
    about: "",
    location: "Haiti",
    profile: estimeProfile, //"https://via.placeholder.com/960x1450.gif",
  },
  {
    fullname: "Soraya Abdella",
    title: "Originals Content Director",
    about: "",
    location: "Canada",
    profile: sorayaProfile, //"https://via.placeholder.com/960x1450.gif",
  },

  {
    fullname: "Kervens Ally",
    title: "Digital Marketing Manager",
    about: "",
    location: "Florida",
    profile: allyProfile, //"https://via.placeholder.com/960x1450.gif",
  },
];
