import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Container, Segment, Dropdown } from "semantic-ui-react";

import { Logo } from "components";

const Navigation = ({ brand, items }) => {
  // const [activeMenu, setActiveMenu] = useState(items[0].name);
  const location = useLocation();

  // useEffect(() => {
  //   let path = location.pathname.substring(1);
  //   if(path === '/')
  //   setActiveMenu(x);
  // });

  const renderNavbarSmallScreenDevices = () => {
    return (
      <div
        className="mobile tablet only"
        style={{
          position: "absolute",
          top: "0",
          width: "100%",
          zIndex: "1111",
        }}
      >
        <Segment style={{ borderRadius: "0", margin: 0 }} basic>
          <Container>
            <Menu secondary borderless size="huge">
              <Menu.Item> {brand ? brand : <Logo size="small" />}</Menu.Item>
              <Menu.Menu position="right">
                <Dropdown
                  className="link item"
                  icon="bars"
                  fluid
                  style={{ color: "#fff" }}
                >
                  <Dropdown.Menu>
                    {items &&
                      items.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          text={
                            <span style={{ textTransform: "capitalize" }}>
                              {item.name}
                            </span>
                          }
                          active={location.pathname === item.path}
                          as={Link}
                          to={item.path}
                        />
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </Container>
        </Segment>
      </div>
    );
  };

  const renderNavbarLargeScreenDevices = () => (
    <div
      className="computer widescreen largescreen only"
      style={{
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: "1111",
      }}
    >
      <Segment
        style={{
          borderRadius: "0",
          margin: 0,
          background: "transparent",
          border: 0,
          boxShadow: "none",
        }}
      >
        <Container>
          <Menu secondary borderless size="huge">
            <Menu.Item>
              {brand ? brand : <Logo style={{ width: "200px" }} />}
            </Menu.Item>
            <Menu.Menu position="right">
              {items &&
                items.map((item, index) => (
                  <Menu.Item
                    key={index}
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    name={item.name}
                    active={location.pathname === item.path}
                    as={Link}
                    to={item.path}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
    </div>
  );

  return (
    <>
      {renderNavbarLargeScreenDevices()}
      {renderNavbarSmallScreenDevices()}
    </>
  );
};
export default Navigation;
