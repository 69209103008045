import React from "react";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import logo from "static/assets/img/logo.png";
import invertedLogo from "static/assets/img/logo-inverted.png";

export function Logo({ inverted, href, ...rest }) {
  return (
    <>
      {inverted ? (
        <Image
          src={invertedLogo}
          {...rest}
          as={href ? Link : Image}
          to={href}
        />
      ) : (
        <Image src={logo} {...rest} as={href ? Link : Image} to={href} />
      )}
    </>
  );
}

Logo.defaultProps = {
  inverted: false,
};
