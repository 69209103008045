import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGa from "react-ga";
import "semantic-ui-css/semantic.min.css";

class App extends Component {
  componentDidMount() {
    // listening for route change
    this.unlisten = this.props.history.listen((location, action) => {
      const { pathname } = location;
      ReactGa.pageview(pathname);
      // TODO Track invalid route

      window.scroll({
        top: 0,
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>{this.props.children}</div>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
