import React from "react";
import { Link } from "react-router-dom";
import heroImage from "static/assets/img/bohiobackground.jpg";
import heroImage2 from "static/assets/img/hero-2.jpeg";
import { Layout } from "components";
import { NAVIGATION_LINKS } from "const";
import { HeroTitle } from "./HomePage.constants";
import Navigation from "components/Navbar/Navigation";
import { HeroBanner, Section } from "bohio-components";
import { StagesSection, AboutSection, ContactSection } from "./components";
import "./homePage.css";

function HomePage() {
  return (
    <Layout
      hero={
        <HeroBanner
          autoPlay
          autoPlayInterval={4500}
          animationType="fadeout"
          infinite
          disableDotsControls
          disableButtonsControls
          images={[heroImage, heroImage2]}
          height={"85vh"}
          header={HeroTitle}
          textAlign="left"
          ctas={[
            {
              as: Link,
              to: "/contact",
              label: "Contact Us",
              icon: "envelope",
              iconColor: "#c13939",
              size: "large",
            },
          ]}
        />
      }
      navigation={<Navigation items={NAVIGATION_LINKS.PUBLIC} />}
    >
      <Section backgroundColor={"linear-gradient(#101010, #060d19)"}>
        <AboutSection />
      </Section>
      <Section backgroundColor={"linear-gradient(to top, #060d19, #c13939)"}>
        <div style={{ margin: "6em 0" }}>
          <StagesSection />
        </div>
        <div style={{ margin: "10em 0 5em 0" }}>
          <ContactSection />
        </div>
      </Section>
    </Layout>
  );
}

export default HomePage;
