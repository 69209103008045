import React from "react";
import { Logo, Button } from "components";
import { Segment, Grid, Header, Icon, List } from "semantic-ui-react";

const FirstRow = () => {
  return (
    <Grid stackable>
      <Grid.Column width={8}>
        <Logo centered style={{ width: "70%" }} />
        <Header
          as="h2"
          icon
          style={{
            marginTop: "0.5em",
            fontStyle: "italic",
            fontWeight: "300",
            color: "#fff",
          }}
          textAlign="center"
        >
          BETTER WATCH IT
        </Header>
      </Grid.Column>
      <Grid.Column floated="right" width={8}>
        <Header as="h1" inverted>
          Experience the Haitian culture with a different perspective on a
          unique platform
        </Header>
        <p style={{ color: "#eee", fontSize: "1.25em" }}>
          We are the first Haitian video on demand streaming platform which is a
          video media distribution system that allows users to access video
          entertainment in the Haitian community
        </p>
        <p>
          <Button
            as="a"
            inverted
            href="https://bohiotv.com"
            externalLink={true}
            content="visit now"
            icon="arrow right"
            iconColor="#c13939"
          />
        </p>
      </Grid.Column>
    </Grid>
  );
};

const Card = ({ item }) => {
  return (
    <Segment inverted padded="very" style={{ backgroundColor: "#ddd" }} raised>
      <Icon name={item.icon} size="huge" style={{ color: "#c13939" }} />

      <Header as="h2" inverted style={{ color: "#101010" }}>
        {item.title}
      </Header>
      <div style={{ color: "#212121", fontSize: "1.15em" }}>
        {item.description}
      </div>
    </Segment>
  );
};

const SecondRow = () => {
  const blocks = [
    {
      icon: "rocket",
      title: "Our Vision & Mission",
      description: `To present the beauty of Haitian culture and entertainment to the world by showcasing and supporting young talented Haitian film producers`,
    },
    {
      icon: "handshake outline",
      title: "Becoming A Partner",
      description: `BohioTV wants to partner with Haitian movie, documentary and other entertainment producers to stream their work on a single platform`,
    },
    {
      icon: "gift",
      title: "The Benefits",
      description: (
        <List>
          <List.Item>- Expose Your Material To A Global Audience</List.Item>
          <List.Item>
            - Monetize Your Content Through Our Subscription &amp; Rental
            Service
          </List.Item>
        </List>
      ),
    },
  ];
  return (
    <>
      <ResponsiveCard blocks={blocks} only="large screen" />
      <ResponsiveCard blocks={blocks} only="computer" />
      <ResponsiveCard blocks={blocks} only="tablet mobile" />
    </>
  );
};

const ResponsiveCard = ({ blocks, only }) => {
  const cols = only === "tablet mobile" ? "1" : "3";
  return (
    <Grid columns={cols} stackable>
      <Grid.Row stretched only={only}>
        {blocks &&
          blocks.map((block, index) => (
            <Grid.Column key={index}>
              <Card item={block} />
            </Grid.Column>
          ))}
      </Grid.Row>
    </Grid>
  );
};

const HomeAbout = () => (
  <>
    <Grid verticalAlign="middle" textAlign="center" stackable>
      <Grid.Row>
        <Grid.Column>
          <FirstRow />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div style={{ margin: "8em 0 5em 0" }}>
            <SecondRow />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
);

export default HomeAbout;
