import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";

//Styles
import styles from "./styles.module.css";

const ContactForm = ({ onSubmit }) => (
  <div>
    <Formik
      initialValues={ContactFormConfig.initialValues}
      validationSchema={ContactFormConfig.schema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleChange, errors }) => (
        <Form size="large" onSubmit={handleSubmit} className="attached">
          <Segment padded style={{ backgroundColor: "#ffffff2e" }}>
            {ContactFormConfig.fields.map((field, index) => {
              return (
                <>
                  {field.type === "input" && (
                    <Form.Input
                      key={index}
                      fluid
                      icon={field.icon}
                      iconPosition="left"
                      placeholder={field.placeholder}
                      type={field.inputType}
                      onChange={handleChange(field.name)}
                      required={field.required}
                      error={errors[field.name]}
                    />
                  )}

                  {field.type === "textarea" && (
                    <Form.TextArea
                      placeholder="Message"
                      onChange={handleChange("message")}
                      required={field.required}
                      error={errors["message"]}
                      rows="3"
                      style={{ resize: "none" }}
                    />
                  )}
                </>
              );
            })}

            <Button className={styles.button} type="submit" fluid>
              Submit
            </Button>
          </Segment>
        </Form>
      )}
    </Formik>
  </div>
);

const ContactFormConfig = {
  initialValues: {
    name: "",
    email: "",
    phone: "",
    message: "",
  },
  fields: [
    {
      icon: "user",
      placeholder: "Name",
      type: "input",
      inputType: "text",
      name: "name",
      required: true,
    },
    {
      icon: "mail",
      placeholder: "Email Address",
      type: "input",
      inputType: "email",
      name: "email",
      required: true,
    },
    {
      icon: "phone",
      placeholder: "Phone Number",
      type: "input",
      inputType: "tel",
      name: "phone",
      required: false,
    },
    {
      icon: null,
      placeholder: "Message",
      type: "textarea",
      name: "message",
      required: false,
    },
  ],
  schema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email("Not a valid email address"),
    phone: Yup.string().required(),
    message: Yup.string(),
  }),
};

export default ContactForm;
