import React from "react";
import { Segment, Grid, Header, Icon } from "semantic-ui-react";

const Card = ({ mode = "block", ...rest }) => {
  return (
    <>
      {mode === "inline" ? <CardInline {...rest} /> : <CardBlock {...rest} />}
    </>
  );
};

const CardBlock = ({
  item,
  textAlign = "center",
  bgColor = "#E0E0E0",
  ...rest
}) => {
  return (
    <Segment
      padded="very"
      style={{ backgroundColor: bgColor, textAlign }}
      raised
      {...rest}
    >
      <Segment
        raised
        style={{
          background: "#c13939",
          border: "1px solid #c13939",
          width: "100px",
          margin: "0 auto",
        }}
      >
        <Icon
          name={item.icon}
          size="huge"
          style={{
            color: "#fff",
            margin: "0",
          }}
        />
      </Segment>
      <Header as="h2">{item.title}</Header>
      <div style={{ fontSize: "1.15em", color: "#333" }}>
        {item.description}
      </div>
    </Segment>
  );
};

const CardInline = ({
  item,
  textAlign = "center",
  bgColor = "#E0E0E0",
  ...rest
}) => {
  return (
    <Segment style={{ backgroundColor: bgColor, textAlign }} raised {...rest}>
      <Grid
        container
        verticalAlign="middle"
        textAlign="left"
        columns="2"
        padded
      >
        <Grid.Column width="4" textAlign="center">
          <Segment
            raised
            style={{
              background: "#c13939",
              border: "1px solid #c13939",
              width: "100px",
              margin: "0 auto",
            }}
          >
            <Icon
              name={item.icon}
              size="huge"
              style={{
                color: "#fff",
                margin: "0",
              }}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column floated="right" width="11">
          <Header as="h3">{item.title}</Header>
          <div
            style={{
              color: "#333",
              fontSize: "1.15em",
            }}
          >
            {item.description}
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

const StagesSection = () => {
  const stages = [
    {
      icon: "file alternate",
      title: "ACCEPT TERMS",
      description: "The terms and Condition is the begining of our partnership",
    },
    {
      icon: "upload",
      title: "UPLOAD CONTENTS",
      description: `Adding the necessary information
        related to the content such as
        director, casting and synopsis`,
    },
    {
      icon: "money",
      title: "MONETIZE",
      description: `The partner has the ability to
        select a minimum rental fee for
        each movie on our platform while
        sharing a percentage with the
        platform.`,
    },
  ];
  return (
    <Grid stackable verticalAlign="middle" textAlign="center" inverted>
      <Grid.Column width={6}>
        <Header as="h1" icon inverted>
          <Icon name="signal" />
          THE STEPS
        </Header>
      </Grid.Column>
      <Grid.Column floated="right" textAlign="left" tablet="16" computer="8">
        {stages &&
          stages.map((stage, index) => (
            <React.Fragment key={index}>
              <Card
                item={stage}
                bgColor="#fff"
                className="computer largescreen widescreen only"
                mode="inline"
              />
              <Card
                item={stage}
                bgColor="#fff"
                className="mobile tablet only"
              />
            </React.Fragment>
          ))}
      </Grid.Column>
    </Grid>
  );
};

export default StagesSection;
