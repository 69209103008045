import React from "react";
import { Link } from "react-router-dom";
import { Grid, List } from "semantic-ui-react";

function Footer({
  container,
  color,
  copyrightLabel,
  socialMedias,
  siteMaps,
  ...rest
}) {
  return (
    <>
      <footer {...rest}>
        <Grid
          columns={3}
          stackable
          container={container}
          verticalAlign="middle"
          textAlign="center"
          style={{ padding: "2em 0 1em 0", fontSize: "1.1em", color: color }}
        >
          <Grid.Column>{copyrightLabel}</Grid.Column>

          <Grid.Column>
            <List horizontal>
              {siteMaps &&
                siteMaps.map((siteMap, index) => (
                  <List.Item
                    key={index}
                    content={
                      <>
                        {siteMap.internal ? (
                          <Link to={siteMap.path} style={{ color: color }}>
                            {siteMap.label}
                          </Link>
                        ) : (
                          <a
                            href={siteMap.path}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: color }}
                          >
                            {siteMap.label}
                          </a>
                        )}
                      </>
                    }
                    style={{
                      color: "#fff",
                    }}
                  />
                ))}
            </List>
          </Grid.Column>

          <Grid.Column>
            <List horizontal size="big" inverted>
              {socialMedias &&
                socialMedias.map((account, index) => (
                  <List.Item
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    as="a"
                    href={account.link}
                  >
                    <List.Icon
                      name={account.icon}
                      inverted
                      style={{ color: account.color }}
                    />
                  </List.Item>
                ))}
            </List>
          </Grid.Column>
        </Grid>
      </footer>
    </>
  );
}

export default Footer;
