import React from "react";
import PropTypes from "prop-types";
import constants from "const";
import { Footer } from "components";

function renderHeroNaviagtionContainer(hero, navigation) {
  return (
    <div style={{ position: "relative" }}>
      {hero}
      {navigation}
    </div>
  );
}

function renderFooter() {
  return (
    <Footer
      color="#000000"
      style={{ backgroundColor: "#f0f0f0" }}
      container
      copyrightLabel={
        <>&copy;&nbsp;2020 BohioTV Medias Partners - All Rights Reserved</>
      }
      siteMaps={constants.navigations.public}
      socialMedias={constants.SocialMedias}
    />
  );
}

export default function Layout({ navigation, hero, children }) {
  return (
    <>
      {renderHeroNaviagtionContainer(hero, navigation)}
      <div>{children}</div>
      {renderFooter()}
    </>
  );
}

Layout.propTypes = {
  hero: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  hero: null,
  children: null,
};
