import axios from "axios";

const API = {
  loginClient: axios.create({
    baseURL: process.env.REACT_APP_BOHIOTV_API_PATH,
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_BOHIOTV_JWT_TOKEN,
    },
    // xsrfHeaderName: 'X-CSRFToken',
    // xsrfCookieName: 'csrftoken',
    // withCredentials: true,
  }),

  async getAboutPage() {
    try {
      const params = {
        populate: "teamMember",
        fields: "header",
      };
      const response = await API.loginClient.get("/about", { params });
      return response.data.data.attributes;
    } catch (e) {
      console.error(e);
    }
  },

  async sendContactEmailNotification(data) {
    return await API.loginClient.post("/api/emails", data);
  },
};

export default API;
