import React from "react";
import { Link } from "react-router-dom";
import { Button } from "components";
import { Segment, Grid, Header, Icon, Image } from "semantic-ui-react";

const ContactSection = () => (
  <Grid stackable verticalAlign="middle" textAlign="center">
    <Grid.Row>
      <Grid.Column width={6}>
        <Header as="h1" inverted>
          OUR PARTNERS
        </Header>
        <Segment basic>
          <Grid columns="equal">
            <Grid.Column>
              <Image
                inline
                circular
                size="tiny"
                style={{ margin: "1em" }}
                src="https://dtsvkkjw40x57.cloudfront.net/images/author/31423/small_ooJrN5ZNQyuLicvVanUQ_RomanKreyol_20wo_20tagline.jpg"
              />
            </Grid.Column>
            <Grid.Column>
              <Image
                inline
                circular
                size="tiny"
                style={{ margin: "1em" }}
                src="https://yt3.ggpht.com/ytc/AAUvwng60assuNJaeSBqeQzUAmjwn1mIJ58tFXvBApMaeQ=s900-c-k-c0x00ffffff-no-rj"
              />
            </Grid.Column>
          </Grid>
        </Segment>
      </Grid.Column>
      <Grid.Column width={8} floated="right">
        <Segment basic padded="very">
          <Header as="h2" icon inverted>
            <Icon
              name="question"
              style={{
                color: "#ffbd59",
              }}
            />
            Want to join us in this amazing journey
          </Header>

          <Button
            as={Link}
            inverted
            to="/contact"
            content="contact us"
            icon="envelope"
            iconColor="#c13939"
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default ContactSection;
