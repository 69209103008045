import React, { Component } from "react";
import { Grid, Header, Flag, List, Message, Icon } from "semantic-ui-react";
import ContactForm from "components/forms/ContactForm";
import heroImage from "static/assets/img/haitiflag.jpg";
import { Layout, Button } from "components";
import Navigation from "components/Navbar/Navigation";
import { NAVIGATION_LINKS } from "const";
import Api from "utils/api";

import { Section, HeroBanner } from "bohio-components";
import { BOHIOTV_CONTACTS } from "./ContactPage.constants";

const MESSAGE_TIMEOUT_SECONDS = 4000;

class ContactPage extends Component {
  state = {
    notification: {
      message: null,
      type: null,
      icon: null,
    },
  };
  contactFormHandler = async ({ name, email, phone, message }) => {
    try {
      await Api.sendContactEmailNotification({
        name,
        email,
        phone,
        message,
      });
      this.clearNotification();
      this.handleMessageNotification(
        "Email successfully sent",
        "success",
        "checkmark"
      );
    } catch (e) {
      this.handleMessageNotification(
        "An unexpected error occured, please verify you internet connection",
        "error",
        "ban"
      );
      console.error("errror");
    }
  };

  clearNotification = () =>
    this.setState({ notification: { type: "", message: "", icon: "" } });

  handleMessageNotification = (message, type, icon) => {
    this.setState({ notification: { type, message, icon } });
    setTimeout(() => this.clearNotification(), MESSAGE_TIMEOUT_SECONDS);
  };

  renderContactSection = () => {
    const { notification } = this.state;
    return (
      <Section backgroundColor="linear-gradient(to left, #131313, #0c1a32)">
        <Grid stackable verticalAlign="middle" textAlign="left">
          <Grid.Column width={8}>
            <div style={{ marginBottom: "1.5em" }}>
              <Header as="h1" inverted>
                ANY QUESTIONS ?
              </Header>
              <Header as="h2" inverted>
                Give us a call or fill out the contact form and our team will
                get back to you.
              </Header>
              {this.renderConctactList()}
            </div>
          </Grid.Column>
          <Grid.Column floated="right" width={6}>
            <div>
              <ContactForm onSubmit={this.contactFormHandler} />
              {notification.message && (
                <Message
                  attached="bottom"
                  error={notification.type === "error"}
                  success={!(notification.type === "error")}
                >
                  <Icon name={notification.icon} />
                  {notification.message}
                </Message>
              )}
            </div>
          </Grid.Column>
        </Grid>
      </Section>
    );
  };

  renderConctactList = () => {
    return (
      <List size="huge">
        {BOHIOTV_CONTACTS &&
          BOHIOTV_CONTACTS.map((contact, index) => (
            <List.Item
              key={index}
              icon={contact.icon}
              content={
                <a
                  href={contact.link}
                  style={{ color: "#fff" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contact.text}
                </a>
              }
              style={{ color: "#fff" }}
            />
          ))}
      </List>
    );
  };

  render() {
    return (
      <Layout
        hero={
          <HeroBanner
            disableButtonsControls={true}
            disableDotsControls={true}
            images={[heroImage]}
            height={"50vh"}
            style={{ position: "absolute", top: 0 }}
            textAlign="center"
            header={"CONTACT US"}
            ctas={
              <>
                <Button
                  as="a"
                  href="tel:+17863402928"
                  content="(786) 340-2928"
                  icon={<Flag name="us" />}
                  externalLink={true}
                />
                <div style={{ padding: "5px" }} className="mobile only" />
                <Button
                  as="a"
                  href="tel:+50933149894"
                  content=" (509) 33 14 98 94"
                  icon={<Flag name="haiti" />}
                  externalLink={true}
                />
              </>
            }
          />
        }
        navigation={<Navigation items={NAVIGATION_LINKS.PUBLIC} />}
      >
        {this.renderContactSection()}
      </Layout>
    );
  }
}

export default ContactPage;
