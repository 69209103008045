import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

export default class PublicRoute extends PureComponent {
  render() {
    const isLoggedIn = localStorage.getItem("authToken");
    const { restricted } = this.props;
    if (isLoggedIn && restricted) return <Redirect to={"/dashboard"} />;
    else return <Route {...this.props} />;
  }
}

PublicRoute.propTypes = {
  exact: PropTypes.bool,
  restricted: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
};

PublicRoute.defaultProps = {
  exact: true,
  restricted: false,
  isLoggedIn: false,
};
