import React from "react";
import { Container, Grid } from "semantic-ui-react";
import heroImage from "static/assets/img/haitiflag.jpg";
import { Layout } from "components";
import Navigation from "components/Navbar/Navigation";
import { NAVIGATION_LINKS, BOHIOTV_TEAM_MEMBERS } from "const";
import { TeamMemberCard, HeroBanner } from "bohio-components";

function AboutPage() {
  return (
    <Layout
      hero={
        <HeroBanner
          disableButtonsControls={true}
          disableDotsControls={true}
          images={[heroImage]}
          height={"50vh"}
          style={{ position: "absolute", top: 0 }}
          textAlign="center"
          header={"Meet The Team"}
          ctas={null}
        />
      }
      navigation={<Navigation items={NAVIGATION_LINKS.PUBLIC} />}
    >
      <div
        style={{
          background: "linear-gradient(to bottom, #101010, #060d19)",
          padding: "8em 0",
        }}
      >
        <Container>
          <Grid columns={4}>
            {BOHIOTV_TEAM_MEMBERS.map((member, index) => (
              <Grid.Column
                largeScreen="4"
                computer="4"
                mobile="8"
                tablet="5"
                key={index}
              >
                <TeamMemberCard
                  color="red"
                  raised
                  fullname={member.fullname}
                  location={member.location}
                  title={member.title}
                  profile={
                    member.profile
                      ? member.profile
                      : "https://via.placeholder.com/960x1450.gif"
                  }
                />
              </Grid.Column>
            ))}
          </Grid>
        </Container>
      </div>
    </Layout>
  );
}

export default AboutPage;
